import type { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const CheckCircleHero = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM15.61 10.186C15.67 10.1061 15.7134 10.0149 15.7377 9.91795C15.762 9.82098 15.7666 9.72014 15.7514 9.62135C15.7361 9.52257 15.7012 9.42782 15.6489 9.3427C15.5965 9.25757 15.5276 9.18378 15.4463 9.12565C15.3649 9.06753 15.2728 9.02624 15.1753 9.00423C15.0778 8.98221 14.9769 8.97991 14.8785 8.99746C14.7801 9.01501 14.6862 9.05205 14.6023 9.10641C14.5184 9.16077 14.4462 9.23135 14.39 9.314L11.154 13.844L9.53 12.22C9.38783 12.0875 9.19978 12.0154 9.00548 12.0188C8.81118 12.0223 8.62579 12.101 8.48838 12.2384C8.35097 12.3758 8.27225 12.5612 8.26882 12.7555C8.2654 12.9498 8.33752 13.1378 8.47 13.28L10.72 15.53C10.797 15.6069 10.8898 15.6662 10.992 15.7036C11.0942 15.7411 11.2033 15.7559 11.3118 15.7469C11.4202 15.738 11.5255 15.7055 11.6201 15.6519C11.7148 15.5982 11.7967 15.5245 11.86 15.436L15.61 10.186Z"
      fill="#9C4EFF"
    />
  </svg>
);

export const SampleLogo = (props: Props) => (
  <svg width={68} height={12} viewBox="0 0 68 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_72_636)">
      <path
        d="M31.416 4.1C31.416 1.9 29.954 0.233333 26.928 0.233333H20.842V11.7333H23.698V2.73333H26.928C28.118 2.73333 28.56 3.4 28.56 4.16667C28.56 4.96667 28.118 5.6 26.928 5.6H24.174L28.118 11.7333H31.382C31.382 11.7333 28.696 7.6 28.73 7.6C30.362 7.23333 31.416 6.1 31.416 4.1ZM16.082 0.233333H18.938V11.7333H16.082V0.233333ZM6.936 0.233333L0.951996 11.7667H4.182L5.134 9.83333H9.962L8.772 7.4H6.358L8.126 3.86667H8.16L12.172 11.7667H15.47L9.486 0.233333H6.936ZM41.854 5.76667C42.772 5.26667 43.248 4.5 43.248 3.33333C43.248 1.46667 41.82 0.233333 39.474 0.233333H32.776V11.7333H39.712C42.126 11.7333 43.724 10.4667 43.724 8.5C43.758 7.16667 42.976 6.16667 41.854 5.76667ZM35.632 2.66667H39.44C40.018 2.66667 40.46 3.1 40.46 3.66667C40.46 4.23333 40.018 4.66667 39.44 4.66667H35.632V2.66667ZM39.542 9.33333H35.632V7H39.542C40.222 7 40.766 7.5 40.766 8.16667C40.766 8.83333 40.222 9.33333 39.542 9.33333ZM52.768 6.66667C52.768 8.4 51.952 9.36667 50.32 9.36667C48.722 9.36667 47.906 8.4 47.906 6.66667V0.233333H44.982V6.46667C44.982 10.0333 46.886 12 50.354 12C53.822 12 55.726 10.0333 55.726 6.46667V0.233333H52.802V6.66667H52.768ZM63.342 4.83333C60.622 4.2 60.044 4.13333 60.044 3.36667C60.044 2.76667 60.724 2.5 61.88 2.5C63.41 2.5 65.008 2.86667 65.926 3.43333L66.844 1.1C65.62 0.433333 63.92 0 61.88 0C58.854 0 57.154 1.46667 57.154 3.46667C57.154 5.6 58.412 6.46667 61.336 7.06667C63.614 7.56667 64.09 7.86667 64.09 8.5C64.09 9.16667 63.478 9.46667 62.22 9.46667C60.418 9.46667 58.82 9.03333 57.528 8.33333L56.644 10.8C58.072 11.5333 60.18 12 62.288 12C65.246 12 67.014 10.6667 67.014 8.4C67.048 6.56667 65.858 5.4 63.342 4.83333Z"
        fill="#C2C2C2"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={68} height={12} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GithubCircleIcon = (props: Props) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_72_623)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.97616 -1.52588e-05C3.56555 -1.52588e-05 0 3.66665 0 8.20282C0 11.8288 2.28457 14.8982 5.45388 15.9845C5.85012 16.0662 5.99527 15.808 5.99527 15.5908C5.99527 15.4007 5.9822 14.7488 5.9822 14.0697C3.76343 14.5587 3.30139 13.0918 3.30139 13.0918C2.94482 12.1412 2.41649 11.8968 2.41649 11.8968C1.69029 11.3943 2.46939 11.3943 2.46939 11.3943C3.27494 11.4487 3.69763 12.2363 3.69763 12.2363C4.41061 13.4857 5.55951 13.1327 6.02171 12.9153C6.08767 12.3856 6.2991 12.019 6.52359 11.8153C4.75396 11.6252 2.89208 10.919 2.89208 7.76815C2.89208 6.87182 3.20882 6.13848 3.71069 5.56815C3.63151 5.36448 3.35412 4.52232 3.79004 3.39515C3.79004 3.39515 4.46351 3.17782 5.98204 4.23715C6.63218 4.05759 7.30265 3.96625 7.97616 3.96548C8.64963 3.96548 9.33616 4.06065 9.97012 4.23715C11.4888 3.17782 12.1623 3.39515 12.1623 3.39515C12.5982 4.52232 12.3207 5.36448 12.2415 5.56815C12.7566 6.13848 13.0602 6.87182 13.0602 7.76815C13.0602 10.919 11.1984 11.6115 9.41551 11.8153C9.70612 12.0733 9.9569 12.5622 9.9569 13.3363C9.9569 14.4363 9.94384 15.3191 9.94384 15.5907C9.94384 15.808 10.0891 16.0662 10.4852 15.9846C13.6545 14.898 15.9391 11.8288 15.9391 8.20282C15.9522 3.66665 12.3736 -1.52588e-05 7.97616 -1.52588e-05Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GoogleIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" {...props}>
    <path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
    <path d="M1 1h22v22H1z" fill="none" />
  </svg>
);

export const DotsIcon = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 6.75C11.8011 6.75 11.6103 6.67098 11.4697 6.53033C11.329 6.38968 11.25 6.19891 11.25 6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6C12.75 6.19891 12.671 6.38968 12.5303 6.53033C12.3897 6.67098 12.1989 6.75 12 6.75ZM12 12.75C11.8011 12.75 11.6103 12.671 11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25C12.1989 11.25 12.3897 11.329 12.5303 11.4697C12.671 11.6103 12.75 11.8011 12.75 12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75ZM12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18C11.25 17.8011 11.329 17.6103 11.4697 17.4697C11.6103 17.329 11.8011 17.25 12 17.25C12.1989 17.25 12.3897 17.329 12.5303 17.4697C12.671 17.6103 12.75 17.8011 12.75 18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75Z"
      stroke="#C1C1C1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EnterKey = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={21} viewBox="0 0 27 21" fill="none" {...props}>
    <mask id="a" fill="#fff">
      <path d="M0 6a5 5 0 0 1 5-5h17a5 5 0 0 1 5 5v9a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5z" />
    </mask>
    <path fill="#fff" d="M0 6a5 5 0 0 1 5-5h17a5 5 0 0 1 5 5v9a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5z" />
    <path
      fill="#E9E9E9"
      d="M-.5 6A5.5 5.5 0 0 1 5 .5h17A5.5 5.5 0 0 1 27.5 6h-1A4.5 4.5 0 0 0 22 1.5H5A4.5 4.5 0 0 0 .5 6zm28 10.5A5.5 5.5 0 0 1 22 22H5a5.5 5.5 0 0 1-5.5-5.5l1-1.5c0 1.657 2.015 3 4.5 3h17c2.485 0 4.5-1.343 4.5-3zM5 22a5.5 5.5 0 0 1-5.5-5.5V6A5.5 5.5 0 0 1 5 .5v1A4.5 4.5 0 0 0 .5 6v9c0 1.657 2.015 3 4.5 3zM22 .5A5.5 5.5 0 0 1 27.5 6v10.5A5.5 5.5 0 0 1 22 22v-4c2.485 0 4.5-1.343 4.5-3V6A4.5 4.5 0 0 0 22 1.5z"
      mask="url(#a)"
    />
    <path
      fill="#8A8A8A"
      d="M5.344 12.296h2.6V13H4.6V7.4h3.304v.704h-2.56v1.72h2.36v.696h-2.36zm5.618-3.4c.936 0 1.552.624 1.552 1.648V13h-.696v-2.416c0-.656-.376-1.024-.992-1.024-.64 0-1.16.376-1.16 1.32V13H8.97V9h.696v.576c.296-.472.736-.68 1.296-.68m4.9.776h-1.064v2.168c0 .608.352.568 1.064.536V13c-1.2.16-1.76-.16-1.76-1.16V9.672h-.792V9h.792v-.912l.696-.208V9h1.064zm1.309 1.648c.136.72.704 1.128 1.448 1.128.552 0 .952-.256 1.152-.584l.592.336c-.352.544-.96.904-1.76.904-1.288 0-2.144-.904-2.144-2.104 0-1.184.848-2.104 2.096-2.104 1.208 0 1.992.992 1.992 2.112q0 .156-.024.312zm1.384-1.768c-.752 0-1.272.464-1.384 1.16h2.672c-.12-.792-.688-1.16-1.288-1.16m3.688.12c.256-.56.744-.744 1.248-.744v.728c-.6-.024-1.248.28-1.248 1.224V13h-.696V9h.696z"
    />
  </svg>
);
