export const Pattern = ({ className }: { className: string }) => {
    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            viewBox="0 0 1512 824"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            patternUnits="userSpaceOnUse"
        >
            <g clip-path="url(#clip0_1570_732)">
                <g style={{ mixBlendMode: "color-burn" }} opacity="0.09">
                    <path
                        d="M-190 -0.508049H1701M-190 40.0836H1701M-190 80.6753H1701M-190 121.267H1701M-190 161.859H1701M-190 202.45H1701M-190 243.042H1701M-190 283.633H1701M-190 324.225H1701M-190 364.817H1701M-190 405.408H1701M-190 446H1701M-190 486.592H1701M-190 527.183H1701M-190 567.775H1701M-190 608.367H1701M-190 648.958H1701M-190 689.55H1701M-190 730.142H1701M-190 770.733H1701M-190 811.325H1701M-190 851.916H1701M-190 892.508H1701M734.639 -80L734.639 972M1668.3 -80V972M694.045 -80L694.045 972M1627.71 -80V972M653.451 -80L653.451 972M1587.11 -80V972M612.857 -80L612.857 972M1546.52 -80V972M572.264 -80L572.263 972M1505.92 -80V972M531.67 -80V972M1465.33 -80V972M491.076 -80L491.076 972M1424.74 -80V972M450.482 -80L450.482 972M1384.14 -80V972M409.888 -80L409.888 972M1343.55 -80V972M369.294 -80V972M1302.95 -80V972M328.7 -80V972M1262.36 -80V972M288.106 -80L288.106 972M1221.77 -80V972M247.512 -80L247.512 972M1181.17 -80V972M206.918 -80L206.918 972M1140.58 -80V972M166.324 -80V972M1099.98 -80V972M125.73 -80L125.73 972M1059.39 -80V972M85.1366 -80L85.1365 972M1018.8 -80V972M44.5426 -80L44.5426 972M978.203 -80V972M3.94872 -80L3.94867 972M937.609 -80L937.609 972M-36.6452 -80L-36.6453 972M897.015 -80L897.015 972M-77.2391 -80L-77.2392 972M856.421 -80L856.421 972M-117.833 -80L-117.833 972M815.827 -80L815.827 972M-158.427 -80L-158.427 972M775.233 -80V972"
                        stroke="url(#paint0_radial_1570_732)"
                        strokeWidth={0.6}
                    />
                </g>
            </g>
            <defs>
                <radialGradient
                    id="paint0_radial_1570_732"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(755.5 446) rotate(90) scale(1099.33 1976.07)"
                >
                    <stop stop-opacity="0" />
                    <stop offset="0.535" stop-color="#202020" />
                    <stop offset="1" stop-opacity="0" />
                </radialGradient>
                <clipPath id="clip0_1570_732">
                    <rect width="1512" height="913.302" fill="white" />
                </clipPath>
                <pattern id="pattern" x="0" y="0" width="100%" height="100%" patternUnits="userSpaceOnUse">
                    <use href="#clip0_1570_732" x="0" y="0" />
                    <use href="#clip0_1570_732" x="100%" y="0" />
                    <use href="#clip0_1570_732" x="0" y="100%" />
                    <use href="#clip0_1570_732" x="100%" y="100%" />
                </pattern>
            </defs>
        </svg>
    );
};

// Move this to design system
export const Noise = ({ className }: { className: string }) => {
    return (
        <svg className={className} height="100%" width="100%">
            <filter id="noise-filter">
                <feTurbulence type="fractalNoise" baseFrequency="0.62" numOctaves="220" stitchTiles="stitch"></feTurbulence>
                <feColorMatrix type="saturate" values="0"></feColorMatrix>
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.81"></feFuncR>
                    <feFuncG type="linear" slope="1.81"></feFuncG>
                    <feFuncB type="linear" slope="1.81"></feFuncB>
                    <feFuncA type="linear" slope="0.97"></feFuncA>
                </feComponentTransfer>
                <feComponentTransfer>
                    <feFuncR type="linear" slope="2.55" intercept="-0.77" />
                    <feFuncG type="linear" slope="2.55" intercept="-0.77" />
                    <feFuncB type="linear" slope="2.55" intercept="-0.77" />
                </feComponentTransfer>
            </filter>
            <rect width="100%" height="100%" filter="url(#noise-filter)"></rect>
        </svg>
    );
};
