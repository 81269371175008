import React from "react";

export const Heading = ({
    type = "h1",
    size,
    children,
    color = "black-900",
}: {
    type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    size: string;
    children: React.ReactNode;
    color?: string;
}) => {
    return React.createElement(
        type,
        {
            className: `ds-font-600 ds-font-heading ds-tracking-[-.2px] ds-text-${color}`,
            style: {
                fontSize: size,
            },
        },
        children,
    );
};
