const FONT_FAMILY = {
    heading: "Nexa, sans-serif",
    text: "Cera Pro, sans-serif",
    mono: "IBM Plex Mono, monospace",
};

const FONT_WEIGHT = {
    300: "300",
    400: "400",
    500: "500",
    600: "600",
    700: "700",
    800: "800",
    900: "900",
};

const FONT_SIZE = {
    "10": "10px",
    "11": "11px",
    "12": "12px",
    "13": "13px",
    "14": "14px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "32": "32px",
    "40": "40px",
    "48": "48px",
    "60": "60px",
    "72": "72px",
};

const LINE_HEIGHT = {
    // For button text
    none: "100%",
    tight: "15`0%",
    relaxed: "170%",
    // For paragraph text
    content: "200%",
    // For body text
    "10": "10px",
    "12": "12px",
    "14": "14px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "32": "32px",
    "40": "40px",
    "48": "48px",
    "60": "60px",
    "72": "72px",
};

const LETTER_SPACING = {
    "0": "0",
    "-1": "-0.25px",
    "-2": "-0.5px",
    "-3": "-0.75px",
    "1": "0.25px",
    "2": "0.5px",
    "3": "0.75px",
};

export const FONT = {
    FAMILY: FONT_FAMILY,
    WEIGHT: FONT_WEIGHT,
    SIZE: FONT_SIZE,
    LINE_HEIGHT: LINE_HEIGHT,
    LETTER_SPACING: LETTER_SPACING,
};
