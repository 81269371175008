import { COLORS } from "design-system/ui/base/tokens/colors";
import { FONT } from "design-system/ui/base/tokens/font";
import React from "react";
import { cn } from "../../../shadcn/lib/utils";

export const Text = ({
    type = "span",
    size = "13",
    children,
    weight = FONT.WEIGHT[400],
    // @ts-ignore
    color = COLORS.BLACK[100],
    className,
}: {
    type?: "p" | "span" | "div" | "h4" | "h5" | "h6";
    size?: (typeof FONT.SIZE)[keyof typeof FONT.SIZE];
    children: React.ReactNode;
    weight?: (typeof FONT.WEIGHT)[keyof typeof FONT.WEIGHT];
    lineHeight?: number;
    color?: (typeof COLORS)[keyof typeof COLORS] | any;
    className?: string;
}) => {
    return React.createElement(
        type,
        {
            className: cn(`ds-font-${weight} ds-text-${size} ds-font-text tracking-[-.2px] ds-text-${color}`, className),
            style: {
                letterSpacing: Number(size) < 13 ? "0.2px" : "0px",
                fontSize: `${size}px`,
            },
        },
        children,
    );
};
