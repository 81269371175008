import { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import { getCookie } from "~/utils/cookie";
import { INITIAL_PAGE_AFTER_LOGIN } from "~/utils/global";
import { LoginScreen } from "../ui/screens/NonAuthLayout/loginScreen";

const useIsUserAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const isLoggedIn = getCookie("isLoggedIn");

    if (isLoggedIn === "true") {
      setIsAuthenticated(true);
      router.push(INITIAL_PAGE_AFTER_LOGIN);
    }
  }, [router]);

  return isAuthenticated;
};

const LoginPage = () => {
  useIsUserAuthenticated();

  return (
    <>
      <Head>
        <title>Login | Composio</title>
      </Head>
      <LoginScreen />
    </>
  );
};

export default LoginPage;
