"use client";

import router, { useRouter } from "next/router";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import * as Sentry from "@sentry/nextjs";

import { postGoogleLogin } from "~/dataProcessor/api/api";
import { setCookie } from "~/utils/cookie";
import { getCurrentClientSideHost, INITIAL_PAGE_AFTER_LOGIN } from "~/utils/global";
import { ANALYTICS } from "~/utils/tracking";

const noop = () => {};

declare namespace global {
  interface Window {
    google: any;
  }
}

const GOOGLE_CLIENT_ID =
  process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ||
  "920687958684-v45uflh67rhr3rou62kcffrmd9lajasi.apps.googleusercontent.com";

export const useGoogleLoginSelector = () => {
  const router = useRouter();

  const postLoginCallback = async (
    data: {
      jwt?: string;
      authCode?: string;
    },
    type: "auth-code" | "jwt",
  ) => {
    try {
      const { email } = await postGoogleLogin(data, type);

      if (email) {
        await ANALYTICS.identify(email, {
          name: email,
          email: email,
        });
      }

      setCookie("isLoggedIn", "true", 30);
      setCookie("composio_email", email || "", 30);
      Sentry.setUser({
        email: email,
      });
      router.push(INITIAL_PAGE_AFTER_LOGIN);
      // You can add more logic here, such as redirecting the user or storing the tokens
    } catch {
      alert("Some error occurred while trying to log you in. Please try after sometime");
    }
  };

  const login = useGoogleLogin({
    onSuccess: (credentialResponse: any) => {
      postLoginCallback({ authCode: credentialResponse.code }, "auth-code");
    },
    onError: noop,
    flow: "auth-code",
    ux_mode: "redirect",
    select_account: true,
    redirect_uri: `${getCurrentClientSideHost()}/provider_login/google`,
  });

  return login;
};

export const useGithubLoginSelector = () => {
  const currentHost = getCurrentClientSideHost();
  const url = `${currentHost}/provider_login/github`;

  return () => {
    router.push(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/login/github/auth?redirectUri=${encodeURIComponent(url)}`);
  };
};

const GoogleLoginComponent = ({ children }: { children: React.ReactNode }) => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} onScriptLoadError={() => {}}>
      {children}
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
