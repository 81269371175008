const apps = [
  {
    icon: "https://www.google.com/gmail/about/static/images/logo-gmail.png",
    title: "Gmail",
    subtitle: "Send an email",
  },
  {
    icon: "https://github.githubassets.com/favicons/favicon.svg",
    title: "GitHub",
    subtitle: "Create a new repository",
  },
  {
    icon: "https://calendar.google.com/googlecalendar/images/favicon_v2018_256.png",
    title: "Calendar",
    subtitle: "Schedule a meeting",
  },
  {
    icon: "https://www.redditstatic.com/desktop2x/img/favicon/favicon-32x32.png",
    title: "Reddit",
    subtitle: "Create a new post",
  },
  {
    icon: "https://trello.com/favicon.ico",
    title: "Trello",
    subtitle: "Create a new board",
  },
  {
    icon: "https://www.notion.so/images/favicon.ico",
    title: "Notion",
    subtitle: "Create a new page",
  },
  {
    icon: "https://jira.atlassian.com/favicon.ico",
    title: "Jira",
    subtitle: "Create a new ticket",
  },
  {
    icon: "https://a.slack-edge.com/80588/marketing/img/meta/favicon-32.png",
    title: "Slack",
    subtitle: "Send a message",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/ComposioHQ/open-logos@master/discord.svg",
    title: "Discord",
    subtitle: "Join a channel",
  },
  {
    icon: "https://abs.twimg.com/favicons/twitter.2.ico",
    title: "Twitter",
    subtitle: "Create a new tweet",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/ComposioHQ/open-logos@master/figma.svg",
    title: "Figma",
    subtitle: "Create a new design",
  },
  {
    icon: "https://www.salesforce.com/favicon.ico",
    title: "Salesforce",
    subtitle: "Update the Lead",
  },
];

export { apps };
