import { Text } from "design-system/ui/atoms/texts/text";
import { MoveDown } from "lucide-react";
import Marquee from "react-fast-marquee";

import { apps } from "./constants";

export const AppBox = ({ icon, title, subtitle }: { icon: string; title: string; subtitle: string }) => {
  return (
    <div className="ds-border-half ds-border-white-400 ds-bg-white-0 flex min-w-[fit-content] gap-[6px] rounded-[12px] px-[16px] py-[14px] !pr-[40px]">
      <img src={icon} alt={title} className="h-[20px] w-[20px]" />
      <div className="ml-[4px] flex flex-col gap-[4px] leading-none">
        <Text type="p" size="14" weight="500" color="black-900" className="leading-[14px]">
          {title}
        </Text>
        <Text type="p" size="11" weight="400" color="gray-600" className="leading-[11px]">
          {subtitle}
        </Text>
      </div>
    </div>
  );
};

export const AppsFooter = () => {
  return (
    <div className="z-[100] flex flex-col items-center">
      <Text type="p" size="15" weight="400" color="black-600" className="mb-[4px] text-center">
        Give your agents access to real world
      </Text>
      <MoveDown className="ds-text-black-1000 mx-auto mb-[20px]" strokeWidth={1.2} />
      <div className="max-w-[100vw] overflow-hidden">
        <Marquee gradient={false} speed={40}>
          <div className="flex items-center gap-6 px-3">
            {apps.map((app) => (
              <AppBox key={app.title} icon={app.icon} title={app.title} subtitle={app.subtitle} />
            ))}
          </div>
        </Marquee>
      </div>
    </div>
  );
};
